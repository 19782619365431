.portfolio {
	padding: 0 0 50px 0;
	/* position: relative; */
	height: auto;
	/* background-image: url('../../assets/img/home-bg.png'); */
	align-items: center;
	margin-bottom: 32px;
}
.portfolio-bx {
	background: linear-gradient(90.21deg, rgba(53, 151, 28, 0.437) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
	border-radius: 64px;
	text-align: center;
	padding: 60px 50px;
	/* margin-top: -60px; */
}
.about h2 {
	font-size: 45px;
	font-weight: 700;
}
.portfolio p {
	color: #b8b8b8;
	font-size: 18px;
	letter-spacing: 0.8px;
	line-height: 1.5em;
	margin: 14px 0 75px 0;
}
.card {
	width: 320px;
	height: 240px;
	border-radius: 16px;
	overflow: auto;
}
.card img {
	width: 100%;
	height: 100%;
}
.button-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}
