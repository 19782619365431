.proj-imgbx {
	position: relative;
	border-radius: 30px;
	overflow: hidden;
	margin-top: 32px;
	margin-bottom: 24px;
	height: 240px;
	display: flex;
	align-items: center;
}
.proj-imgbx:hover {
	cursor: pointer;
}
.proj-imgbx::before {
	content: '';
	background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
	opacity: 0.85;
	position: absolute;
	width: 100%;
	height: 0;
	transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
	height: 100%;
}
.proj-txtx {
	position: absolute;
	text-align: center;
	top: 65%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: 0.5s ease-in-out;
	opacity: 0;
	width: 100%;
}
.proj-imgbx:hover .proj-txtx {
	top: 50%;
	opacity: 1;
}
.proj-txtx h4 {
	font-size: 30px;
	font-weight: 700;
	letter-spacing: 0.8px;
	line-height: 1.1em;
}
.proj-txtx span {
	font-style: italic;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0.8px;
}
.mt-3{
	margin-top: 0.75rem;
}
.mx-3{
margin: 0 1rem;
}
.flex-center{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.btn{
	padding: 0.5rem 1rem;
	color:ghostwhite;
}
.btn:hover{
	background-color: #54214A;
	color:ghostwhite ;
	transform: scale(1.1);
}
.btn-fe{
	background-color: #5B7194;
}
.btn-be{
	background-color: #00000095;
}