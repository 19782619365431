.skill {
	/* display: flex;
	align-items: center;
	justify-content: center; */
	height: auto;
	padding: 0 0 50px 0;
	/* background-image: url('../../assets/img/17520.jpg'); */
}
.skill-bx {
	background: linear-gradient(90.21deg, rgba(27, 28, 7, 0.437) -5.91%, rgba(26, 187, 215, 0.5) 111.58%);
	border-radius: 64px;
	text-align: center;
	padding: 60px 50px;
	/* margin-top: -60px; */
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill p {
	color: #b8b8b8;
	font-size: 18px;
	letter-spacing: 0.8px;
	line-height: 1.5em;
	margin: 14px 0 75px 0;
}
.skill-slider {
	width: 80%;
	margin: 0 auto;
	position: relative;
}
.background-image-left {
	top: 28%;
	position: absolute;
	bottom: 0;
	width: 40%;
	z-index: -4;
}
